import React, { Component } from 'react';
import { render } from 'react-dom';
import { Route, Link, BrowserRouter as Router, Switch } from 'react-router-dom'
import ReactDOM from 'react-dom'
import './style.css';
import Privacy from './privacy'
import Notfound from './notfound'

class App extends Component {
  constructor() {
    super();
    this.state = {
      name: 'there'
    };
  }

  render() {
    return (
      <div id="root">
        <div id="container">
          <img className='logo' src="https://firebasestorage.googleapis.com/v0/b/fizcult-fc9f4.appspot.com/o/static%2Fimg%2Ficon.png?alt=media&token=60d7204e-50c7-4069-9e31-bbf2c52f9c7a"></img>
          <h1>
            Fizcult
          </h1>
          <p>
            Build your own workout plan and transform your body
          </p>
          <a target={'_blank'} href="https://apps.apple.com/ru/app/id1451452701">
            <img className='appstore_button' src="https://firebasestorage.googleapis.com/v0/b/weight-tracker-19b86.appspot.com/o/icons%2Fappstore_us.svg?alt=media&token=11f332d5-4b85-4312-a068-0dff1d8fd2ef"></img>
          </a>
        </div>
        <div id="screenshotcontainer">
          <br></br>
          <img className='screenshots' src="https://firebasestorage.googleapis.com/v0/b/fizcult-fc9f4.appspot.com/o/static%2Fimg%2Fscreens.png?alt=media&token=f1300308-850a-4edd-9f39-834e6d160b33"></img>
        </div>
      </div>
    );
  }
}

const routing = (
  <Router>
    <switch>
      <Route exact path="/" component={App} />
      <Route path="/privacy" component={Privacy} />
    </switch>
  </Router>
)

render(routing, document.getElementById('root'));
